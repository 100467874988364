.App {
    text-align: center;
    margin-bottom: 5rem;

    .Loading {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
